import Vue from 'vue'
import VueRouter from 'vue-router'

Vue.use(VueRouter)

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes: [
    {
      path: '/:lang/:name/:hash',
      name: 'main',
      component: () => import('@/views/Home.vue')
    },
    {
      path: '/error-404',
      name: 'page-error-404',
      component: () => import('@/views/Error404.vue'),
      meta: {
        rule: 'guest'
      }
    },
    {
      path: '*',
      redirect: '/error-404'
    }
  ]
})

export default router
