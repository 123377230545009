// en, ar
export default {
  en: {
    'Thank you for choosing us!': 'Thank you for choosing us!',
    'New order': 'New order',
    'Kindly fill in the form below to make sure your order is placed and on its way to you..': 'Kindly fill in the form below to make sure your order is placed and on its way to you..',
    'First name': 'First name',
    'Sec name': 'Last name',
    'Full name': 'Full name',
    'Email address':'Email address',
    'Phone number': 'Phone number',
    'Governorate *': 'Governorate *',
    'City *': 'City *',
    'Zone *': 'Zone *',
    'Customer Address 1':'Building Number, Street Name',
    'Customer Address 2':'Apartment Number, Floor Number',
    'Landmark?': 'Landmark?',
    'Locate your Address!': 'Locate your Address!',
    'Help us find your address below so that we don\'t have to call you for directions!': 'Help us find your address below so that we don\'t have to call you for directions!',
    'Your location is:': 'Your location is:',
    'Search / copy your location..': 'Search / copy your location..',
    'Locate me!': 'Locate me!',
    'Place Order': 'Place Order',
    'You\'ve successfully placed an order with:': 'You\'ve successfully placed an order with:',
    'To be delivered to:': 'To be delivered to:',
    'what’s': 'what’s',
    'Having your own business?': 'Having your own business?',
    'Start shipping with us now..': 'Start shipping with us now..',
    'Please, allow location services on your device!': 'Please, allow location services on your device!'
  },
  ar: {
    'Thank you for choosing us!': 'شكرا على اختيارك',
    'New order': 'طلب جديد',
    'Kindly fill in the form below to make sure your order is placed and on its way to you..': 'سجل معلوماتك لتنفيذ طلبك والتأكد من وصوله اليك..',
    'First name': 'الأسم الأول',
    'Sec name': 'الأسم الأخير',
    'Full name': 'الأسم الكامل',
    'Email address':'عنوان البريد الألكترونى',
    'Phone number': 'رقم الهاتف المحمول',
    'Governorate *': '* المحافظة',
    'City *': '* المدينة',
    'Zone *': '* المنطقة',
    'Customer Address 1': 'العنوان (رقم البيت، أسم الشارع)',
    'Customer Address 2': 'العنون (رقم الشقة، رقم الدور)',
    'Landmark?': 'علامة مميزة',
    'Locate your Address!': 'حدد موقعك بسهولة!',
    'Help us find your address below so that we don\'t have to call you for directions!': 'ساعدنا نلاقي مكانك بسهوله من غير مكالمات نسألك فيها عن مكان الاستلام!',
    'Your location is:': 'مكانك:',
    'Search / copy your location..': 'ابحث عن / ضع عنوانك هنا..',
    'Locate me!': 'حدد مكانى!',
    'Place Order': 'تأكيد الطلب',
    'You\'ve successfully placed an order with:': 'تم تأكيد طلبك بنجاح من:',
    'To be delivered to:': 'طلبك هيوصلك على العنوان التالى:',
    'what’s': 'ما هو',
    'Having your own business?': 'تمتلك مشروعك الخاص؟',
    'Start shipping with us now..': 'ابدأ شحن معانا!',
    'Please, allow location services on your device!': 'من فضلك، قم بالسماح للتطبيق باستخدام خدمات الموقع الجغرافي!'
  }
}
