<template>
  <div id="app">
    <router-view />
  </div>
</template>

<script>
export default {
  watch: {
    '$route.params.lang' () {
      const dir = this.$route.params.lang === 'en' ? 'ltr' : 'rtl'
      document.documentElement.setAttribute('dir', dir)
      if (this.$route.params.lang === 'en') {
        this.$i18n.locale = 'en'
      } else {
        this.$i18n.locale = 'ar'
      }
    }
  }
}
</script>
<style>
</style>