import Vue from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import 'material-icons/iconfont/material-icons.css'

import * as VueGoogleMaps from 'vue2-google-maps'
Vue.use(VueGoogleMaps, {
  load: {
    key: process.env.VUE_APP_GOOGLE_MAPS_API_KEY,
    libraries: 'places'
  },
  installComponents: true
})

// Tailwind
import '@/assets/css/main.css'

//MIXPANEL
import VueMixpanel from 'vue-mixpanel'
Vue.use(VueMixpanel, {
  token: process.env.VUE_APP_MIXPANEL_TOKEN,
  config: {
    debug: false
  }
})

// VeeValidate
import VeeValidate from 'vee-validate'
Vue.use(VeeValidate)

import i18n from './i18n/i18n'
Vue.use(require('vue-cookies'))
if (!Vue.$cookies.get('locale')) {
  Vue.$cookies.set('locale', 'ar', '100y')
}

import vSelect from 'vue-select'
import 'vue-select/dist/vue-select.css'
Vue.component('v-select', vSelect)

Vue.config.productionTip = false

new Vue({
  router,
  store,
  i18n,
  render: (h) => h(App)
}).$mount('#app')
